import React from 'react';
// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      web3school海外备用网站~正在建设中.....
    </div>
  );
}

export default App;
